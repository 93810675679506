
import React from "react"
import profilePic from '../assets/dp.jpg';

import { rhythm } from "../utils/typography"

const Bio = () => {
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <img
        src={profilePic}
        alt={'Lawliet'}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          width: 50,
          height: 50,
          borderRadius: `100%`,
        }}
        imgstyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Personal Blog by <a href={`https://twitter.com/one_tiny_step`}>Lawliet</a>
        <br/>
        My learnings in words 📈
      </p>
    </div>
  )
}

export default Bio
